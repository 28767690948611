import * as React from "react";

import { Box } from "@components/box";
import { GatsbyLink } from "@components/gatsby-link";
import { Paragraph } from "@components/typography";
import arrow from "@images/ic-arrow-long-right-blue.svg";

import { LearnSectionLinksContainers } from "../styles";
import { LearnSectionLinksProps } from "../types";

export const LearnSectionLinks = (props: LearnSectionLinksProps) => (
  <LearnSectionLinksContainers appearance={props.appearance}>
    <Paragraph className="!font-500">Resources</Paragraph>
    <Box display="flex" className="mt-[1.5rem] flex-col space-y-0.5">
      {props.links.map((link, key) => (
        <GatsbyLink key={key} to={link.slug}>
          <Box display="flex" className="space-x-1">
            <span>{link.title}</span>
            <img src={arrow} alt="Link" />
          </Box>
        </GatsbyLink>
      ))}
    </Box>
  </LearnSectionLinksContainers>
);
