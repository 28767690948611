import { useState, useEffect } from "react";
import { ScreenSizeType } from "@types";

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSizeType | undefined>();

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        if (window.innerWidth <= 768) setScreenSize("sm");
        else if (window.innerWidth <= 1024) setScreenSize("md");
        else if (window.innerWidth <= 1366) setScreenSize("lg");
        else setScreenSize("xl");
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return screenSize;
};
