import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { LearnSection } from "@components/learn-section";
import { PageLayout, SEO } from "@components/page-layout";
import { GrayPhrase } from "@components/typography";
import { Box } from "@components/box";
import { useScreenSize } from "../helpers/useScreenSize";
import { CenterHeader } from "@containers/header";
import { Products } from "@containers/products";
import { getImage } from "gatsby-plugin-image";

const getLinksFromSlug = (slug: string, data: any) => {
  let links: { slug: string; title: string }[] = [];

  data.nodes.forEach((node: any) => {
    if (node.section) {
      if (node.section.slug === slug)
        links.push({ slug: `${slug}/${node.slug}`, title: node.title });
    }
  });

  return links;
};

const Learn = () => {
  const { allContentfulLearnArticle, allContentfulLearnSection, file } = useStaticQuery(graphql`
    {
      allContentfulLearnArticle {
        nodes {
          slug
          title
          section {
            slug
          }
        }
      }
      allContentfulLearnSection {
        nodes {
          title
          slug
          description {
            description
          }
        }
      }
      file(relativePath: {eq: "fullscreen-background.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1190)
        }
      }
    }
  `);

  const screenSize = useScreenSize();

  return (
    <PageLayout>
      <SEO title="Learn" />
      <CenterHeader
        description="Everything you need to know to manage your finances. Whether you are a novice or a money expert, we will help you to achieve the financial freedom you are looking for."
        src={getImage(file)?.images.fallback?.src}
        srcSet={getImage(file)?.images.fallback?.srcSet || ""}
        download={
          screenSize === ("xl" || "lg")
            ? " the app and get back on track to financial health anywhere, at any time."
            : " the app and borrow up to $1,500."
        }
      >
        <GrayPhrase>Personal</GrayPhrase> finance hub
      </CenterHeader>
      <Box className="pb-[2.75rem] md:pb-0 sm:pb-[1.5rem]">
        {allContentfulLearnSection.nodes.map((node: any, id: number) =>
          node.description ? (
            <LearnSection
              appearance={id % 2 === 0 ? "even" : "odd"}
              description={node.description.description}
              links={getLinksFromSlug(node.slug, allContentfulLearnArticle)}
              slug={node.slug}
              title={node.title}
            />
          ) : null
        )}
        <Products />
      </Box>
    </PageLayout>
  );
};

export default Learn;
