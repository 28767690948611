import styled from "styled-components";
import tw from "tailwind-styled-components";

import { Box } from "@components/box";

import { LearnSectionContainerProps } from "../types";

const StyledLearnSectionLinksContainers = styled(Box).attrs({
  styledCss: "width: 616px; border-radius: 50px;",
  lg: { styledCss: "width: 80%;" },
  sm: { styledCss: "width: 100%;" },
})<LearnSectionContainerProps>``;

export const LearnSectionLinksContainers = tw(
  StyledLearnSectionLinksContainers
)`
  p-[2.75rem]
  md:p-[2rem]
  sm:px-[11px]

  lg:mt-[2.75rem]

  mx-[2.75rem]

  lg:max-w-[660px]
  sm:max-w-auto

  lg:ml-[300px]
  md:ml-0

  ${(p) => {
    switch (p.appearance) {
      case "even":
        return "bg-interface-50";
      case "odd":
      default:
        return "bg-interface-100";
    }
  }}
`;
