import * as React from "react";

import { LearnSectionTitle, LearnSectionLinks } from "../components";
import { LearnSectionContainer } from "../styles";
import { LearnSectionProps } from "../types";

export const LearnSection = (props: LearnSectionProps) => {
  const { appearance, description, slug, title, links } = props;

  return (
    <LearnSectionContainer appearance={appearance}>
      <LearnSectionTitle description={description} slug={slug} title={title} />
      <LearnSectionLinks appearance={appearance} links={links} />
    </LearnSectionContainer>
  );
};
