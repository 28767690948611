import styled from "styled-components";
import tw from "tailwind-styled-components";

import { LearnSectionContainerProps } from "../types";

const StyledLearnSectionContainer = styled.div<LearnSectionContainerProps>``;

export const LearnSectionContainer = tw(StyledLearnSectionContainer)`
  flex

  ${(p) => {
    switch (p.appearance) {
      case "even":
        return "bg-interface-100";
      case "odd":
      default:
        return "bg-transparent";
    }
  }}

  w-full
  justify-center
  py-[6rem]
  md:py-[2.75rem]
  sm:px-[22px]

  lg:flex-col
  md:items-center

  md:max-w-[606px]
  md:mx-auto
  md:relative
`;
