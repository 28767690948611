import * as React from "react";

import { Box } from "@components/box";
import { Button } from "@brainfinance/icash-component-library";
import { GatsbyLink } from "@components/gatsby-link";
import { GrayPhrase, Heading, Paragraph } from "@components/typography";
import defaultImg from "@images/default.png";

import { LearnFlavourText } from "../styles";
import { LearnSectionTitleProps } from "../types";

export const LearnSectionTitle = (props: LearnSectionTitleProps) => (
  <Box display="flex" className="lg:mx-[2.75rem] sm:mx-0">
    <Box className="mt-[2.25rem] mr-[4rem] md:absolute md:-left-[7rem] md:pt-[3.75rem] sm:hidden">
      <img width="202px" height="184px" src={defaultImg} className="md:max-w-[128px]" />
    </Box>
    <Box className="w-full max-w-[660px] md:p-[2rem] sm:p-0">
      <Box className="mb-[2.75rem] md:mb-[2.125rem]">
        <Heading>{props.title}</Heading>
      </Box>
      <Box className="mb-[1.5rem] md:mb-[22px]">
        <LearnFlavourText>What you will learn</LearnFlavourText>
      </Box>
      <Box className="mb-[2.75rem] md:mb-[22px]">
        <Paragraph>
          <GrayPhrase>{props.description}</GrayPhrase>
        </Paragraph>
      </Box>
      <GatsbyLink to={props.slug} inheritStyle>
        <Button appearance="tertiary" size="large" className="md:!font-600" onClick={()=>{}}>Start learning</Button>
      </GatsbyLink>
    </Box>
  </Box>
);
